import { Col, Form, Row } from 'reactstrap';
import {
  Dispatch,
  KeyboardEventHandler,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import { formatDate } from 'src/app/stores/contains';
import { DEFAULT_EVENT_DURATION } from 'src/app/helpers/constants';
import { getOffers } from 'src/app/api/offers';
import { isEqual } from 'lodash';
import useConfirmState from 'src/app/hooks/useConfirmState';
import { useAppSelector, useForm, useStateArray, useStateDict } from '../../../app/hooks';
import { FormProps, FormSubmitEvent, FormSubmitProps } from '../../../app/types/Components';
import { stringifyDate, stringifyDateTime } from '../../../app/helpers/manipulation';
import {
  Agency,
  Booking,
  BookingInterpretation,
  BookingStatus,
  Clinic,
  Company,
  Doctor,
  Interpretation,
  Language,
  Note,
  Offer,
  Operator,
  Payer,
  Report,
  Requester,
  ServiceRoot,
  UninsuredAffiliation,
} from '../../../app/types/Entities';
import {
  BookingPostErrorComplex,
  BookingPostPayloadComplex,
  BookingPostResponseComplex,
  BookingPutErrorComplex,
  BookingPutPayloadComplex,
  BookingPutResponseComplex,
  BookingsPostError,
  BookingsPostPayload,
  BookingsPostResponse,
  createBookingComplex,
  EventsPostPayloadCaseFields,
  EventsPostPayloadComplex,
  EventsPutPayloadComplex,
  getBooking,
  getInterpretationRoots,
  getUser,
  MUTATION_KEYS,
  OffersPostPayloadComplex,
  offsetDateByMinutes,
  updateBookingComplex,
} from '../../../app/api';
import { ErrorsAlert } from '../../../components/ErrorsAlert';
import { selectUser } from '../../../app/stores/authSlice';
import {
  CASE_TYPES,
  CaseType,
  CLINIC_AGENT_ROLES,
  COMPANY_TYPES,
  NOTIFICATION_BOOKING_REMINDER_TARGETS,
  NotificationBookingReminderTarget,
  OFFER_STATUS,
  PAYER_TYPES,
  PayerType,
  REQUESTER_TYPES,
  RequesterType,
} from '../../../app/helpers/enum';
import NotesListField from './Fields/NotesListField/NotesListField';
import InterpreterField from './Fields/InterpreterField';
import './Form.scss';
import ClinicField from './Fields/ClinicField';
import RequesterField from './Fields/RequesterField';
import MedicalProviderField from './Fields/MedicalProviderField';
import EventListField from './Fields/EventList/EventListField';
import PayerField from './Fields/PayerField';
import { BookingEvent } from './Fields/EventList/EventItem';
import ServiceField from './Fields/ServiceField/ServiceField';
import FollowUpField, { FollowUpSubmissionPayload } from './Fields/FollowUpField';
import SupportOperatorField from './Fields/SupportOperatorField';
import ReportField from './Fields/ReportField/ReportField';
import { LocalOffer } from './Fields/InterpreterField/OfferRow';
import CaseField, { extractClaimFromEvent, isClaimValid } from './Fields/CaseField';
import { Claim } from './Fields/CaseField/localTypes';
import { PayerFieldState } from './Fields/stateTypes';
import { DatabaseId } from '../../../app/types/DataStructures';
import PatientField from './Fields/PatientField/PatientField';

export interface BookingOnboardingFormProps
  extends FormProps,
    FormSubmitProps<BookingsPostPayload, BookingsPostResponse, BookingsPostError> {
  preloadBooking?: Booking;
  preloadBookingId?: DatabaseId;
  preloadStatus?: 'loading' | 'success' | 'error';
  setPreloadStatus?: (status: 'loading' | 'success' | 'error') => void;
  initialEventStartDate?: Date;
  initialEventEndDate?: Date;
  setBookingStatus?: (status: BookingStatus) => void;
  setCheckIsDirty?: Dispatch<SetStateAction<() => { isDirty: boolean; fieldsDirty: string[] }>>;
  groupBooking?: boolean;
}

const translationArray = ['booking', 'forms', 'languages'];

function BookingOnboardingForm({
  id,
  formPrefix = 'BookingOnboarding',
  preloadBooking,
  preloadBookingId,
  preloadStatus,
  setPreloadStatus,
  initialEventStartDate,
  initialEventEndDate,
  setBookingStatus,
  setCheckIsDirty,
  submitPreventDefault = true,
  preSubmit,
  onSubmitSuccess,
  onSubmitFailure,
  postSubmit,
  groupBooking,
}: BookingOnboardingFormProps) {
  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);
  const queryClient = useQueryClient();
  const bookingProvided = preloadBooking !== undefined;
  const bookingIdProvided = preloadBookingId !== undefined;
  const lastLoadedBookingId = useRef<DatabaseId | undefined>(undefined);
  const [followUp] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  // User
  const { user_id } = useAppSelector(selectUser) ?? {};
  const [userRefetchEnabled, setUserRefetchEnabled] = useState<boolean>(true);
  const { data: user } = useQuery(
    [MUTATION_KEYS.USERS, user_id],
    () => (user_id !== undefined ? getUser(user_id) : undefined),
    {
      enabled: userRefetchEnabled,
    },
  );

  useEffect(() => {
    if (user_id === undefined || user !== undefined) {
      setUserRefetchEnabled(false);
    }
  }, [user_id, user]);

  // Form data
  const [errors, setErrors, updateErrors] = useStateDict<BookingsPostError>({});
  const [currentBookingStatus, setCurrentBookingStatus] = useState<BookingStatus | undefined>();

  // Booking notes
  const [showBookingNotes, setShowBookingNotes] = useState<boolean>(false);
  const {
    values: bookingNotesList,
    set: setBookingNotes,
    push: pushBookingNote,
    replaceAt: replaceBookingNoteAt,
    removeAt: removeBookingNoteAt,
  } = useStateArray<Note>([]);
  const { values: initialBookingNotesList, set: setInitialBookingNotesList } = useStateArray<Note>([]);

  // Clinic
  const [showClinic, setShowClinic] = useState<boolean>(false);
  const [clinic, setClinicWithConfirmation, setClinic] = useConfirmState<Clinic | null>(
    null,
    preloadBooking !== undefined,
    t`clinic`,
    true,
  );
  const [initialClinic, setInitialClinic] = useState<Clinic | null>(null);

  // Event
  const [showEvent, setShowEvent] = useState<boolean>(false);

  const newEvent = (): BookingEvent => {
    return {
      start_at: moment().toDate(),
      description: 'Initial Consultation',
      expected_duration: DEFAULT_EVENT_DURATION,
      arrive_early: 0,
    };
  };

  const {
    values: events,
    set: setEvents,
    push: pushEvent,
    replaceAt: replaceEventAt,
    removeAt: removeEventAt,
  } = useStateArray<BookingEvent>([
    {
      start_at: initialEventStartDate ?? moment().toDate(),
      description: 'Initial Consultation',
      expected_duration:
        initialEventEndDate && initialEventStartDate
          ? moment(initialEventEndDate).diff(moment(initialEventStartDate), 'minutes')
          : DEFAULT_EVENT_DURATION,
      arrive_early: 0,
    },
  ]);

  const { values: initialEventsList, set: setInitialEventsList } = useStateArray<BookingEvent>([
    {
      start_at: initialEventStartDate ?? moment().toDate(),
      description: 'Initial Consultation',
      expected_duration:
        initialEventEndDate && initialEventStartDate
          ? moment(initialEventEndDate).diff(moment(initialEventStartDate), 'minutes')
          : DEFAULT_EVENT_DURATION,
      arrive_early: 0,
    },
  ]);

  // OVERLAP VERIFICATION REMOVED UNTIL WE ARE ABLE TO FIX IT ON BACKEND

  // const getStartAndEndDates = (dateInUTC: string) => {
  //   const dateInPST = moment.tz(dateInUTC, 'America/Los_Angeles');

  //   const startDate = dateInPST.clone().startOf('day').toISOString().replace('Z', '+00:00');

  //   const endDate = dateInPST.clone().endOf('day').toISOString().replace('Z', '+00:00');

  //   return [startDate, endDate];
  // };

  // const checkBookingsOverlap = (patientBookings: Booking[], eventToCheck: BookingEvent) => {
  //   const sortedBookings = [...patientBookings].sort((a, b) =>
  //     a.events && b.events ? a.events[0].start_at.getTime() - b.events[0].start_at.getTime() : 0,
  //   );

  //   const eventToCheckStart = moment(eventToCheck.start_at);
  //   const eventToCheckEnd = moment(eventToCheck.start_at).add(eventToCheck.expected_duration, 'minutes');

  //   for (let i = 0; i < sortedBookings.length; i += 1) {
  //     const A = sortedBookings[i];
  //     if (A.events) {
  //       const aEventStart = moment(A.events[0].start_at);
  //       const aEventEnd = moment(A.events[0].end_at);

  //       if (
  //         eventToCheckStart.isBetween(aEventStart, aEventEnd, undefined, '[]') ||
  //         eventToCheckEnd.isBetween(aEventStart, aEventEnd, undefined, '[]') ||
  //         (eventToCheckStart.isBefore(aEventStart) && eventToCheckEnd.isAfter(aEventEnd))
  //       ) {
  //         return A;
  //       }
  //     }
  //   }
  //   return false;
  // };

  const pathnameCalendarSingleResult = `${window.location.pathname}`;
  const [currentPath, setCurrentPath] = useState(pathnameCalendarSingleResult);

  useEffect(() => {
    if (currentPath !== '/bookings/list') {
      setCurrentPath('/bookings/calendar/');
    }
  }, [currentPath, pathnameCalendarSingleResult]);

  // Follow-up
  const [showFollowUp, setShowFollowUp] = useState<boolean>(false);
  const [parentBookingId, setParentBookingId] = useState<number | undefined>(undefined);
  const [childrenBookingIds, setChildrenBookingIds] = useState<number[]>([]);

  const { values: offers, set: setOffers } = useStateArray<LocalOffer>([]);
  const { values: initialOffers, set: setInitialOffers } = useStateArray<LocalOffer>([]);

  // Medical provider
  const [showMedicalProvider, setShowMedicalProvider] = useState<boolean>(false);
  const [medicalProvider, setMedicalProviderWithConfirmation, setMedicalProvider] = useConfirmState<Doctor | null>(
    null,
    preloadBooking !== undefined,
    t`medicalProvider`,
    true,
  );
  const [initialMedicalProvider, setInitialMedicalProvider] = useState<Doctor | null>(null);

  // Patient and affiliation
  const [showPatient, setShowPatient] = useState<boolean>(false);
  const [affiliation, setAffiliationWithConfirmation, setAffiliation] = useConfirmState<UninsuredAffiliation | null>(
    null,
    preloadBooking !== undefined,
    t`patient`,
    true,
  );
  const [initialAffiliation, setInitialAffiliation] = useState<UninsuredAffiliation | null>(null);

  const hasPatientDoB = !!affiliation?.recipient.date_of_birth;

  // Payer
  const [showPayer, setShowPayer] = useState<boolean>(false);

  const [payerType, setPayerTypeWithConfirmation, setPayerType] = useConfirmState<PayerType>(
    COMPANY_TYPES.INSURANCE,
    preloadBooking !== undefined,
    t`payerType`,
    true,
  );
  const [payerCompany, setPayerCompany] = useState<Company | null>(null);
  const [payer, setPayer] = useState<Payer | null>(null);

  const [initialPayerFieldState, setInitialPayerFieldState] = useState<PayerFieldState>({
    payerType: COMPANY_TYPES.INSURANCE,
    payerCompany: null,
    payer: null,
  });

  // Case
  const [showCase, setShowCase] = useState<boolean>(false);
  const hideCaseField = () => setShowCase(false);

  const [caseType, setCaseTypeWithConfirmation, setCaseType] = useConfirmState<CaseType>(
    CASE_TYPES.WORKERS_COMPENSATION,
    preloadBooking !== undefined,
    t`caseType`,
    true,
  );
  const [initialCaseType, setInitialCaseType] = useState<CaseType>(CASE_TYPES.WORKERS_COMPENSATION);

  const [claimData, setClaimData] = useState<Claim | null>(null);
  const [initialClaimData, setInitialClaimData] = useState<Claim | null>(null);

  const [agency, setAgency] = useState<Agency | null>(null);

  const changeAffiliation = (newAffiliation: typeof affiliation) => {
    setAffiliationWithConfirmation(newAffiliation);
    setClaimData(null);
    setShowPatient(false);
    setShowCase(false);
  };

  // Requester
  const [showRequester, setShowRequester] = useState<boolean>(false);

  const [requester, setRequesterWithConfirmation, setRequester] = useConfirmState<Requester | null>(
    null,
    preloadBooking !== undefined,
    t`requester`,
    true,
  );
  const [initialRequester, setInitialRequester] = useState<Requester | null>(null);

  const [requesterCompanySource, setRequesterCompanySource] = useState<RequesterType | null>(null);
  const [initialRequesterCompanySource, setInitialRequesterCompanySource] = useState<RequesterType | null>(null);

  // Service
  const [showService, setShowService] = useState<boolean>(false);
  const [showInterpreter, setShowInterpreter] = useState<boolean>(false);
  const [getInterpreterSectionOpened, setGetInterpreterSectionOpened] = useState<() => number>(() => 0);

  const [targetLanguage, setTargetLanguageWithConfirmation, setTargetLanguage] = useConfirmState<Language | null>(
    null,
    preloadBooking !== undefined,
    t`language`,
    true,
  );
  const [initialTargetLanguage, setInitialTargetLanguage] = useState<Language | null>(null);

  const [serviceRoot, setServiceRootWithConfirmation, setServiceRoot] =
    useConfirmState<ServiceRoot<Interpretation> | null>(null, preloadBooking !== undefined, t`rootService`, true);
  const [initialServiceRoot, setInitialServiceRoot] = useState<ServiceRoot<Interpretation> | null>(null);

  const [serviceRootsRefetchEnabled, setServiceRootsRefetchEnabled] = useState<boolean>(true);

  const { data: serviceRoots, isLoading: isServiceRootLoading } = useQuery(
    [MUTATION_KEYS.SERVICE_ROOTS],
    () => getInterpretationRoots({}),
    {
      enabled: serviceRootsRefetchEnabled,
    },
  );

  useEffect(() => {
    if (serviceRoots) {
      setServiceRootsRefetchEnabled(false);
    }
  }, [serviceRoots]);

  const [service, setServiceWithConfirmation, setService] = useConfirmState<Interpretation | null>(
    null,
    preloadBooking !== undefined,
    t`interpreter`,
    true,
  );
  const [initialService, setInitialService] = useState<Interpretation | null>(null);

  // Report
  const [showReport, setShowReport] = useState<boolean>(false);
  const [retrieveReport, setRetrieveReport] = useState<() => Report>();
  const [initialReport, setInitialReport] = useState<Report>();

  // Support operator

  const [showSupportOperator, setShowSupportOperator] = useState<boolean>(false);

  const [supportOperator, setSupportOperatorWithConfirmation, setSupportOperator] = useConfirmState<Operator | null>(
    user as Operator,
    preloadBooking !== undefined,
    t`supportOperator`,
    true,
  );
  const [initialSupportOperator, setInitialSupportOperator] = useState<Operator | null>(user as Operator);

  const [sendReminderTarget, setSendReminderTarget] = useState<NotificationBookingReminderTarget | null>(
    NOTIFICATION_BOOKING_REMINDER_TARGETS.ALL,
  );

  // Toggle can submit
  const [canSubmit, setCanSubmit] = useState<boolean>(true);

  useEffect(() => {
    setCanSubmit?.(
      !(
        showCase ||
        showClinic ||
        showEvent ||
        (showInterpreter && getInterpreterSectionOpened() === 0) ||
        showMedicalProvider ||
        showPatient ||
        showPayer ||
        showRequester ||
        showService
      ),
    );
  }, [
    showPatient,
    showClinic,
    showEvent,
    showMedicalProvider,
    showService,
    showRequester,
    showInterpreter,
    showPayer,
    getInterpreterSectionOpened,
    showCase,
  ]);

  useEffect(() => {
    const canceledOffers = offers.map((offer) => {
      if (offer.service.target_language.id !== targetLanguage?.id) {
        return { ...offer, status: OFFER_STATUS.CANCELED };
      }

      return offer;
    });

    setOffers(canceledOffers);
    // Using this useEffect as observer
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetLanguage]);

  useEffect(() => {
    setCheckIsDirty?.(() => () => {
      const fieldsDirty = [];

      if (!isEqual(affiliation, initialAffiliation)) {
        fieldsDirty.push('affiliation');
      }
      if (!isEqual(caseType, initialCaseType)) {
        fieldsDirty.push('case');
      }
      if (!isEqual(claimData, initialClaimData)) {
        fieldsDirty.push('claimData');
      }
      if (!isEqual(clinic, initialClinic)) {
        fieldsDirty.push('clinic');
      }
      if (!isEqual(medicalProvider, initialMedicalProvider)) {
        fieldsDirty.push('medicalProvider');
      }
      if (!isEqual({ payerType, payerCompany, payer }, initialPayerFieldState)) {
        fieldsDirty.push('payer');
      }
      if (!isEqual(requester, initialRequester)) {
        fieldsDirty.push('requester');
      }
      if (!isEqual(requesterCompanySource, initialRequesterCompanySource)) {
        fieldsDirty.push('requesterCompanySource');
      }
      if (!isEqual(service, initialService)) {
        fieldsDirty.push('service');
      }
      if (!isEqual(serviceRoot, initialServiceRoot)) {
        fieldsDirty.push('serviceRoot');
      }
      if (!isEqual(supportOperator, initialSupportOperator)) {
        fieldsDirty.push('supportOperator');
      }
      if (!isEqual(targetLanguage, initialTargetLanguage)) {
        fieldsDirty.push('targetLanguage');
      }
      if (!isEqual(events, initialEventsList)) {
        fieldsDirty.push('events');
      }
      if (!isEqual(bookingNotesList, initialBookingNotesList)) {
        fieldsDirty.push('bookingNotesList');
      }
      if (!bookingProvided && !isEqual(offers, initialOffers)) {
        fieldsDirty.push('offers');
      }
      if (initialReport !== undefined && !isEqual(retrieveReport?.(), initialReport)) {
        fieldsDirty.push('report');
      }

      return {
        isDirty: fieldsDirty.length > 0,
        fieldsDirty,
      };
    });
  }, [
    affiliation,
    bookingNotesList,
    bookingProvided,
    caseType,
    claimData,
    clinic,
    events,
    initialAffiliation,
    initialBookingNotesList,
    initialCaseType,
    initialClaimData,
    initialClinic,
    initialEventsList,
    initialMedicalProvider,
    initialOffers,
    initialPayerFieldState,
    initialReport,
    initialRequester,
    initialRequesterCompanySource,
    initialService,
    initialServiceRoot,
    initialSupportOperator,
    initialTargetLanguage,
    medicalProvider,
    offers,
    payer,
    payerCompany,
    payerType,
    requester,
    requesterCompanySource,
    retrieveReport,
    service,
    serviceRoot,
    setCheckIsDirty,
    supportOperator,
    targetLanguage,
  ]);

  // Mutations
  const mutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries([MUTATION_KEYS.BOOKINGS]).then();
      queryClient.invalidateQueries([MUTATION_KEYS.EVENTS]).then();
    },
  };

  const { mutateAsync: mutateAsyncCreateBooking } = useMutation<
    BookingPostResponseComplex,
    BookingPostErrorComplex,
    BookingPostPayloadComplex
  >(createBookingComplex, mutationOptions);

  const { mutateAsync: mutateAsyncUpdateBooking } = useMutation<
    BookingPutResponseComplex,
    BookingPutErrorComplex,
    Parameters<typeof updateBookingComplex>
  >((args) => updateBookingComplex(...args), mutationOptions);

  // Prefill form from booking id
  const prefillFromBooking = (booking: BookingInterpretation) => {
    // Data extractable from booking
    const prefillClinic = booking.companies.find((company) => company.type === COMPANY_TYPES.CLINIC) as
      | Clinic
      | undefined;
    const prefillOperator = booking.operators[0];
    const prefillService = booking.services[0];
    const prefillServiceRoot = booking.service_root as ServiceRoot<any> | undefined;
    const prefillTargetLanguage = booking.target_language;
    const prefillNotes = booking.notes;

    // Data extractable from event
    const prefillEventList = booking.events?.map(
      (event): BookingEvent => ({
        id: event.id,
        start_at: event.start_at,
        description: event.description,
        expected_duration: moment(event.end_at).diff(moment(event.start_at), 'minutes'),
        arrive_early: moment(event.start_at).diff(moment(event.arrive_at), 'minutes'),
      }),
    );

    const prefillEvent = booking.events?.[0];

    if (!prefillEvent) {
      toast.error(() => t`noEvent`);
      return;
    }

    const prefillAffiliation = prefillEvent.affiliates?.[0] as UninsuredAffiliation | undefined;

    const prefillPayerType = prefillEvent.payer_company_type ?? PAYER_TYPES.PATIENT;
    const prefillPayerCompany = prefillEvent.payer_company ?? null;
    const prefillPayer = prefillEvent.payer ?? null;

    const prefillClaim = extractClaimFromEvent(prefillEvent);

    const prefillMedicalProvider = prefillEvent.agents?.find(
      (agent) => agent.role === CLINIC_AGENT_ROLES.MEDICAL_PROVIDER,
    );

    const prefillRequester = prefillEvent.requester;
    const prefillReport = prefillEvent.reports[0];

    // Set state
    setAffiliation(prefillAffiliation ?? null);
    setInitialAffiliation(prefillAffiliation ?? null);

    setClaimData(prefillClaim);
    setInitialClaimData(prefillClaim);

    setCaseType(prefillEvent.case_type ?? CASE_TYPES.WORKERS_COMPENSATION);
    setInitialCaseType(prefillEvent.case_type ?? CASE_TYPES.WORKERS_COMPENSATION);

    setClinic(prefillClinic ?? null);
    setInitialClinic(prefillClinic ?? null);

    setEvents(prefillEventList ?? []);
    setInitialEventsList(prefillEventList ?? []);

    setMedicalProvider(prefillMedicalProvider ?? null);
    setInitialMedicalProvider(prefillMedicalProvider ?? null);

    setPayerType(prefillPayerType);
    setPayerCompany(prefillPayerCompany);
    setPayer(prefillPayer);
    setInitialPayerFieldState({
      payerType: prefillPayerType,
      payerCompany: prefillPayerCompany,
      payer: prefillPayer,
    });

    setRequester(booking.requester_company_source !== REQUESTER_TYPES.PATIENT ? prefillRequester ?? null : null);
    setInitialRequester(booking.requester_company_source !== REQUESTER_TYPES.PATIENT ? prefillRequester ?? null : null);

    setRequesterCompanySource(booking.requester_company_source ?? null);
    setInitialRequesterCompanySource(booking.requester_company_source ?? null);

    setService(prefillService ?? null);
    setInitialService(prefillService ?? null);

    setServiceRoot(prefillServiceRoot ?? null);
    setInitialServiceRoot(prefillServiceRoot ?? null);

    setSupportOperator(prefillOperator ?? null);
    setInitialSupportOperator(prefillOperator ?? null);

    setTargetLanguage(prefillTargetLanguage ?? null);
    setInitialTargetLanguage(prefillTargetLanguage ?? null);

    setBookingNotes(prefillNotes ?? []);
    setInitialBookingNotesList(prefillNotes ?? []);

    setOffers((booking.offers as LocalOffer[]) ?? []);
    setInitialOffers((booking.offers as LocalOffer[]) ?? []);

    setParentBookingId(booking.parent);
    setChildrenBookingIds(booking.children);

    setBookingStatus?.(booking.cCurrentStatus);
    setCurrentBookingStatus(booking.cCurrentStatus);

    setInitialReport(prefillReport);

    lastLoadedBookingId.current = booking.id;

    // Throw warning if patient has no DoB
    if (!prefillAffiliation?.recipient.date_of_birth) {
      toast.warning(() => t`alerts.noDoBWarningToast`);
    }
  };

  // OVERLAP VERIFICATION REMOVED UNTIL WE ARE ABLE TO FIX IT ON BACKEND

  // const getPatientBookings = useCallback(
  //   (recipientId: number, agentsId: number | undefined, startDate: string, endDate: string) => {
  //     return queryClient
  //       .fetchQuery([MUTATION_KEYS.BOOKINGS, recipientId], () =>
  //         getPatientsBookings({
  //           _recipient_id: recipientId,
  //           _agents_id: agentsId,
  //           _start_date: startDate,
  //           _end_date: endDate,
  //         }),
  //       )
  //       .then((data) => {
  //         return data;
  //       });
  //   },
  //   [queryClient],
  // );

  // const getAgentsBookings = useCallback(
  //   (recipientId: number, agentsId: number | undefined, startDate: string, endDate: string) => {
  //     return queryClient
  //       .fetchQuery([MUTATION_KEYS.BOOKINGS, agentsId], () =>
  //         getAgentBookings({
  //           _recipient_id: recipientId,
  //           _agents_id: agentsId,
  //           _start_date: startDate,
  //           _end_date: endDate,
  //         }),
  //       )
  //       .then((data) => {
  //         return data;
  //       });
  //   },
  //   [queryClient],
  // );

  useEffect(() => {
    if (!preloadBooking) {
      if (preloadBookingId) {
        getBooking(preloadBookingId)
          .then((booking) => {
            prefillFromBooking(booking as BookingInterpretation);
            setPreloadStatus?.('success');
          })
          .catch(() => {
            setPreloadStatus?.('error');
          });
        return;
      }
      lastLoadedBookingId.current = undefined;
      return;
    }

    setPreloadStatus?.('success');

    if (lastLoadedBookingId.current !== preloadBooking.id) {
      prefillFromBooking(preloadBooking as BookingInterpretation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preloadBooking, preloadBookingId, setPreloadStatus]);

  useEffect(() => {
    if (preloadStatus === 'success') {
      const closeButton = document.querySelector('.fc-popover-close') as HTMLElement;
      if (closeButton) closeButton.click();
    }
  }, [preloadStatus]);

  const { refetch: refetchOffers } = useQuery(
    [MUTATION_KEYS.OFFERS, preloadBooking?.id],
    () => (bookingProvided ? getOffers({ booking: preloadBooking?.id }) : Promise.reject()),
    {
      enabled: false,
      onSuccess: (data) => setOffers(data as LocalOffer[]),
    },
  );

  // Form functions
  const validate = useCallback(
    (expectEvents: boolean) => {
      const validationErrors = [];

      if (!user?.operator_id) {
        validationErrors.push(t`errors.userNotOperator`);
      }
      if (!affiliation) {
        validationErrors.push(t`errors.noPatient`);
      }
      if (claimData && !isClaimValid(claimData)) {
        validationErrors.push(t`errors.invalidClaim`);
      }
      if (!clinic) {
        validationErrors.push(t`errors.noClinic`);
      }
      if (!medicalProvider) {
        validationErrors.push(t`errors.noMedicalProvider`);
      }
      if (!requester && requesterCompanySource !== REQUESTER_TYPES.PATIENT) {
        validationErrors.push(t`errors.noRequester`);
      }
      if (!targetLanguage) {
        validationErrors.push(t`errors.noLanguage`);
      }
      if (!serviceRoot) {
        validationErrors.push(t`errors.noServiceRoot`);
      }
      if (payerType === PAYER_TYPES.PATIENT && !affiliation?.recipient?.payer_id) {
        validationErrors.push(t`errors.noPayerPatient`);
      }
      if (expectEvents) {
        events.forEach((event) => {
          if (typeof event.expected_duration !== 'number') {
            validationErrors.push(t`errors.wrongExpectedDuration`);
          }
          if (typeof event.arrive_early !== 'number') {
            validationErrors.push(t`errors.wrongArriveEarly`);
          }
        });
      }
      if (validationErrors) {
        updateErrors({ validation_errors: validationErrors });
      }

      return validationErrors.length === 0;
    },
    [
      affiliation,
      claimData,
      clinic,
      events,
      medicalProvider,
      payerType,
      requester,
      requesterCompanySource,
      serviceRoot,
      t,
      targetLanguage,
      updateErrors,
      user?.operator_id,
    ],
  );

  const submitForCreation = useCallback(
    async (formSubmitEvent?: FormSubmitEvent, followUpSubmissionPayload?: FollowUpSubmissionPayload) => {
      if (submitPreventDefault) {
        formSubmitEvent?.preventDefault();
      }

      // Set errors and validate
      setErrors({});

      if (!validate(true)) {
        return undefined;
      }

      if (bookingProvided && !followUpSubmissionPayload) {
        console.error('Tried creating a booking when an ID was provided');
        return undefined;
      }

      if (!bookingProvided && followUpSubmissionPayload) {
        console.error('Tried creating a follow-up booking when no ID was provided');
        return undefined;
      }

      let canContinue = true;

      if (!canSubmit) {
        canContinue = await Swal.fire({
          title: t`errors.closeAllFields`,
          icon: 'warning',
          confirmButtonText: t`actions.saveAndLoseChanges`,
          confirmButtonColor: '#dc3741',
          showCancelButton: true,
          cancelButtonText: t`actions.cancel`,
          customClass: {
            confirmButton: 'btn action-button-popup',
            cancelButton: 'btn action-button-popup',
          },
        }).then(({ isConfirmed }) => isConfirmed);
      }

      if (!canContinue) {
        return undefined;
      }

      let payloadPayerCompanyId = payerCompany?.id;
      let payloadPayerId = payer?.payer_id;

      if (payerType === PAYER_TYPES.CLINIC) {
        payloadPayerCompanyId = clinic?.id;
        payloadPayerId = undefined;

        if (payloadPayerCompanyId === undefined) {
          console.error('Tried creating a booking with a clinic payer without a clinic');
          return undefined;
        }
      }

      if (payerType === PAYER_TYPES.PATIENT) {
        payloadPayerCompanyId = undefined;
        payloadPayerId = affiliation?.recipient?.payer_id;

        if (payloadPayerId === undefined) {
          console.error('Tried creating a booking with a patient payer without a patient');
          return undefined;
        }
      }

      // Build payload
      const eventCasePayload: EventsPostPayloadCaseFields | null = claimData
        ? {
            claim_number: claimData.claimNumber,
            date_of_injury: stringifyDate(claimData.dateOfInjury),
            diagnosis: claimData.diagnosis,
            claim_insurance_id: claimData.insuranceId,
            claim_adjuster_id: claimData.adjusterId,
            claim_lawfirm_id: claimData.lawfirmId,
            claim_lawyer_id: claimData.lawyerId,
            case_type: caseType,
          }
        : null;

      const buildEventPayload = (event: BookingEvent): EventsPostPayloadComplex => ({
        affiliates: [affiliation!.id!],
        agents: [medicalProvider!.id!],
        requester:
          requesterCompanySource === REQUESTER_TYPES.PATIENT ? affiliation?.recipient.requester_id! : requester!.id!,
        start_at: stringifyDateTime(event.start_at),
        description: event.description,
        arrive_at: offsetDateByMinutes(event.start_at.getTime(), -event.arrive_early as number).toISOString(),
        end_at: offsetDateByMinutes(event.start_at.getTime(), event.expected_duration as number).toISOString(),

        payer_company_type: payerType,
        payer_company: payloadPayerCompanyId ?? null,
        payer: payloadPayerId ?? null,
        authorizations: claimData?.authorizationIds,

        ...eventCasePayload,
      });

      const buildOfferPayload = (offer: Offer): OffersPostPayloadComplex => ({
        status: offer.status!,
        service: offer.service.id!,
      });

      const payloadService = followUpSubmissionPayload ? followUpSubmissionPayload.service : service;
      const bookingEvents = (followUpSubmissionPayload?.events ?? events).map(buildEventPayload);
      const bookingOffers = offers.map(buildOfferPayload);

      const bookingPayload: BookingPostPayloadComplex = {
        companies: [clinic!.id!],
        notes: bookingNotesList,
        operators: supportOperator ? [supportOperator.operator_id!] : [],
        parent: followUpSubmissionPayload ? preloadBooking?.id : undefined,
        services: payloadService ? [payloadService.id!] : [],
        service_root: serviceRoot?.id ?? undefined,
        target_language_alpha3: targetLanguage?.alpha3 ?? undefined,
        requester:
          requesterCompanySource === REQUESTER_TYPES.PATIENT ? affiliation?.recipient.requester_id! : requester!.id!,
        requester_company_source: requesterCompanySource ?? undefined,
        reminder_targets: sendReminderTarget ?? undefined,
        _event_datalist: bookingEvents,
        _offer_datalist: bookingOffers,
        created_by: user ? user.id : undefined,
        group_booking: groupBooking,
      };

      // OVERLAP VERIFICATION REMOVED UNTIL WE ARE ABLE TO FIX IT ON BACKEND

      // const [startDate, endDate] = getStartAndEndDates(bookingEvents[0].start_at);

      // const patientBookings = await getPatientBookings(
      //   affiliation?.recipient.user_id!,
      //   bookingEvents[0].agents?.[0],
      //   startDate,
      //   endDate,
      // );
      // const agentsBookings = await getAgentsBookings(
      //   affiliation?.recipient.user_id!,
      //   bookingEvents[0].agents?.[0],
      //   startDate,
      //   endDate,
      // );

      // const isBookingOverlap = checkBookingsOverlap(
      //   patientBookings,
      //   followUpSubmissionPayload?.events![0] ?? events[0],
      // );

      // if (isBookingOverlap !== false) {
      //   const sameTimeError = await Swal.fire({
      //     title: t`errors.patientBookingsOverlap`,
      //     html: `<p>${t('errors.patientBookignsOverlapMessage')}</p>
      //     <ul>
      //     <li><a target="_blank" href="${currentPath}?eventId=${isBookingOverlap?.events![0].id}">ID: ${
      //       isBookingOverlap.public_id
      //     }</a></li>
      //     </ul>`,
      //     icon: 'error',
      //     showConfirmButton: false,
      //     showCancelButton: true,
      //     cancelButtonText: t`actions.cancel`,
      //     cancelButtonColor: '#dc3741',
      //     allowOutsideClick: true,
      //     width: '40em',
      //     customClass: {
      //       cancelButton: 'btn action-button-popup',
      //     },
      //   });

      //   if (sameTimeError.isDismissed) {
      //     return undefined;
      //   }
      // } else if (patientBookings.length > 0) {
      //   const sameDayWarning = await Swal.fire({
      //     title: t`warnings.patientHasBookings`,
      //     html: `<p>${t('warnings.patientHasBookingsMessage')}</p>
      //     <ul>
      //     ${patientBookings
      //       .map(
      //         (booking) =>
      //           `<li> <a target="_blank" href="${currentPath}?eventId=${booking.events![0].id}">ID: ${
      //             booking.public_id
      //           }</a>`,
      //       )
      //       .join('')}
      //     </ul>`,
      //     icon: 'warning',
      //     confirmButtonText: t`actions.continue`,
      //     confirmButtonColor: '#00a2b8',
      //     showCancelButton: true,
      //     cancelButtonText: t`actions.cancel`,
      //     cancelButtonColor: '#dc3741',
      //     allowOutsideClick: true,
      //     width: '40em',
      //     customClass: {
      //       confirmButton: 'btn action-button-popup',
      //       cancelButton: 'btn action-button-popup',
      //     },
      //   });

      //   if (sameDayWarning.isDismissed) {
      //     return undefined;
      //   }
      // }

      // const isBookingAgentOverlap = checkBookingsOverlap(
      //   agentsBookings,
      //   followUpSubmissionPayload?.events![0] ?? events[0],
      // );

      // if (groupBooking === false || preloadBooking?.group_booking === false) {
      //   if (isBookingAgentOverlap !== false) {
      //     const sameTimeError = await Swal.fire({
      //       title: t`errors.agentBookingsOverlap`,
      //       html: `<p>${t('errors.patientBookignsOverlapMessage')}</p>
      //   <ul>
      //   <li><a target="_blank" href="${currentPath}?eventId=${isBookingAgentOverlap?.events![0].id}">ID: ${
      //         isBookingAgentOverlap.public_id
      //       }</a></li>
      //   </ul>`,
      //       icon: 'error',
      //       showConfirmButton: false,
      //       showCancelButton: true,
      //       cancelButtonText: t`actions.cancel`,
      //       cancelButtonColor: '#dc3741',
      //       allowOutsideClick: true,
      //       width: '40em',
      //       customClass: {
      //         cancelButton: 'btn action-button-popup',
      //       },
      //     });

      //     if (sameTimeError.isDismissed) {
      //       return undefined;
      //     }
      //   }
      // } else if (agentsBookings.length > 0) {
      //   const sameDayWarning = await Swal.fire({
      //     title: t`warnings.agentHasBookings`,
      //     html: `<p>${t('warnings.agentHasBookingsMessage')}</p>
      //   <ul>
      //   ${agentsBookings
      //     .map(
      //       (booking) =>
      //         `<li> <a target="_blank" href="${currentPath}?eventId=${booking.events![0].id}">ID: ${
      //           booking.public_id
      //         }</a>`,
      //     )
      //     .join('')}
      //   </ul>`,
      //     icon: 'warning',
      //     confirmButtonText: t`actions.continue`,
      //     confirmButtonColor: '#00a2b8',
      //     showCancelButton: true,
      //     cancelButtonText: t`actions.cancel`,
      //     cancelButtonColor: '#dc3741',
      //     allowOutsideClick: true,
      //     width: '40em',
      //     customClass: {
      //       confirmButton: 'btn action-button-popup',
      //       cancelButton: 'btn action-button-popup',
      //     },
      //   });

      //   if (sameDayWarning.isDismissed) {
      //     return undefined;
      //   }
      // }

      if (preSubmit && !preSubmit(bookingPayload)) {
        return undefined;
      }

      if (submitting) {
        return undefined;
      }

      setSubmitting(true);

      return toast
        .promise(mutateAsyncCreateBooking(bookingPayload), {
          pending: t`progress.bookingCreate.started` as string,
          error: t`progress.bookingCreate.error` as string,
          success: t`progress.bookingCreate.success` as string,
        })
        .catch((err) => {
          // Booking creation failed
          setErrors(err);
          onSubmitFailure?.(err, bookingPayload);
          return Promise.reject(); // Stop promise chain execution
        })
        .then((booking) => onSubmitSuccess?.(booking.booking_id, bookingPayload))
        .then(() => setSubmitting(false))
        .finally(postSubmit);
    },
    [
      affiliation,
      bookingNotesList,
      bookingProvided,
      canSubmit,
      caseType,
      claimData,
      clinic,
      events,
      medicalProvider,
      mutateAsyncCreateBooking,
      offers,
      onSubmitFailure,
      onSubmitSuccess,
      payer?.payer_id,
      payerCompany?.id,
      payerType,
      postSubmit,
      preSubmit,
      requester,
      requesterCompanySource,
      sendReminderTarget,
      service,
      serviceRoot?.id,
      setErrors,
      submitPreventDefault,
      submitting,
      supportOperator,
      t,
      targetLanguage?.alpha3,
      user,
      validate,
      groupBooking,
      preloadBooking,
    ],
  );

  const submitForUpdate = useCallback(
    async (formSubmitEvent: FormSubmitEvent) => {
      if (submitPreventDefault) {
        formSubmitEvent.preventDefault();
      }

      // Set errors and validate
      setErrors({});

      if (!validate(true)) {
        return;
      }

      if (!bookingProvided && !bookingIdProvided) {
        console.error('Tried updating a booking without knowing its ID first');
        return;
      }

      let canContinue = true;
      if (!canSubmit) {
        canContinue = await Swal.fire({
          title: t`errors.closeAllFields`,
          icon: 'warning',
          confirmButtonText: t`actions.saveAndLoseChanges`,
          confirmButtonColor: '#dc3741',
          showCancelButton: true,
          cancelButtonText: t`actions.cancel`,
          customClass: {
            confirmButton: 'btn action-button-popup',
            cancelButton: 'btn action-button-popup',
          },
        }).then(({ isConfirmed }) => isConfirmed);
      }

      if (!canContinue) {
        return;
      }

      const report = retrieveReport?.()!;

      const reportPayload = {
        id: report.id!,
        status: report.status,
        arrive_at: report.arrive_at ? stringifyDateTime(report.arrive_at) : undefined,
        start_at: report.start_at ? stringifyDateTime(report.start_at) : undefined,
        end_at: report.end_at ? stringifyDateTime(report.end_at) : undefined,
        observations: report.observations,
        event: report.event,
      };

      let payloadPayerCompanyId = payerCompany?.id;
      let payloadPayerId = payer?.payer_id;

      if (payerType === PAYER_TYPES.CLINIC) {
        payloadPayerCompanyId = clinic?.id;
        payloadPayerId = undefined;

        if (payloadPayerCompanyId === undefined) {
          console.error('Tried creating a booking with a clinic payer without a clinic');
          return;
        }
      }

      if (payerType === PAYER_TYPES.PATIENT) {
        payloadPayerCompanyId = undefined;
        payloadPayerId = affiliation?.recipient?.payer_id;

        if (payloadPayerId === undefined) {
          console.error('Tried creating a booking with a patient payer without a patient');
          return;
        }
      }

      // Build payload
      const eventCasePayload: EventsPostPayloadCaseFields = claimData
        ? {
            claim_number: claimData.claimNumber,
            date_of_injury: stringifyDate(claimData.dateOfInjury),
            diagnosis: claimData.diagnosis,
            claim_insurance_id: claimData.insuranceId,
            claim_adjuster_id: claimData.adjusterId,
            claim_lawfirm_id: claimData.lawfirmId,
            claim_lawyer_id: claimData.lawyerId,
            case_type: caseType,
          }
        : {
            claim_number: null,
            date_of_injury: null,
            diagnosis: null,
            claim_insurance_id: null,
            claim_adjuster_id: null,
            case_type: null,
          };

      const buildEventPayload = (event: BookingEvent): EventsPutPayloadComplex => ({
        id: event.id || undefined,
        booking: preloadBooking?.id || preloadBookingId!,
        affiliates: [affiliation!.id!],
        agents: [medicalProvider!.id!],
        requester:
          requesterCompanySource === REQUESTER_TYPES.PATIENT ? affiliation?.recipient.requester_id! : requester!.id!,
        start_at: stringifyDateTime(event.start_at),
        description: event.description,
        arrive_at: offsetDateByMinutes(event.start_at.getTime(), -event.arrive_early as number).toISOString(),
        end_at: offsetDateByMinutes(event.start_at.getTime(), event.expected_duration as number).toISOString(),

        payer_company_type: payerType,
        payer_company: payloadPayerCompanyId ?? null,
        payer: payloadPayerId ?? null,
        authorizations: claimData?.authorizationIds,

        ...eventCasePayload,

        _report_datalist: [reportPayload],
        _deleted: event.deleted ?? false,
      });

      const bookingEvents = events.map(buildEventPayload);

      // Figure out which events should be deleted, those in initialEventsList came from the db
      initialEventsList.forEach((event) => {
        if (!bookingEvents.find((e) => e.id === event.id)) {
          bookingEvents.push(
            buildEventPayload({
              ...event,
              deleted: true,
            }),
          );
        }
      });

      const bookingPayload: BookingPutPayloadComplex = {
        children: childrenBookingIds,
        companies: [clinic!.id!],
        operators: supportOperator ? [supportOperator.operator_id!] : [],
        services: service ? [service.id!] : [],
        service_root: serviceRoot?.id ?? undefined,
        target_language_alpha3: targetLanguage?.alpha3 ?? undefined,
        notes: bookingNotesList,
        requester:
          requesterCompanySource === REQUESTER_TYPES.PATIENT ? affiliation?.recipient.requester_id! : requester!.id!,
        requester_company_source: requesterCompanySource ?? undefined,
        reminder_targets: sendReminderTarget ?? undefined,
        _event_datalist: bookingEvents,
        group_booking: preloadBooking?.group_booking,
      };

      // OVERLAP VERIFICATION REMOVED UNTIL WE ARE ABLE TO FIX IT ON BACKEND

      // const [startDate, endDate] = getStartAndEndDates(bookingEvents[0].start_at);

      // const patientBookings = (
      //   await getPatientBookings(affiliation?.recipient.user_id!, bookingEvents[0].agents?.[0], startDate, endDate)
      // ).filter((booking) => booking.id !== (preloadBooking?.id || preloadBookingId!));

      // const agentsBookings = await getAgentsBookings(
      //   affiliation?.recipient.user_id!,
      //   bookingEvents[0].agents?.[0],
      //   startDate,
      //   endDate,
      // );

      // const isBookingOverlap = checkBookingsOverlap(
      //   patientBookings,
      //   followUpSubmissionPayload?.events![0] ?? events[0],
      // );

      // if (isBookingOverlap !== false) {
      //   const sameTimeError = await Swal.fire({
      //     title: t`errors.patientBookingsOverlap`,
      //     html: `<p>${t('errors.patientBookignsOverlapMessage')}</p>
      //     <ul>
      //     <li><a href="${currentPath}?eventId=${isBookingOverlap?.events![0].id}">ID: ${
      //       isBookingOverlap.public_id
      //     }</a></li>
      //     </ul>`,
      //     icon: 'error',
      //     showConfirmButton: false,
      //     showCancelButton: true,
      //     cancelButtonText: t`actions.cancel`,
      //     cancelButtonColor: '#dc3741',
      //     allowOutsideClick: true,
      //     width: '40em',
      //     customClass: {
      //       cancelButton: 'btn action-button-popup',
      //     },
      //   });

      //   if (sameTimeError.isDismissed) {
      //     return;
      //   }
      // } else if (patientBookings.length > 0) {
      //   const sameDayWarning = await Swal.fire({
      //     title: t`warnings.patientHasBookings`,
      //     html: `<p>${t('warnings.patientHasBookingsMessage')}</p>
      //     <ul>
      //     ${patientBookings
      //       .map(
      //         (booking) =>
      //           `<li> <a href="${currentPath}?eventId=${booking.events![0].id}">ID: ${booking.public_id}</a>`,
      //       )
      //       .join('')}
      //     </ul>`,
      //     icon: 'warning',
      //     confirmButtonText: t`actions.continue`,
      //     confirmButtonColor: '#00a2b8',
      //     showCancelButton: true,
      //     cancelButtonText: t`actions.cancel`,
      //     cancelButtonColor: '#dc3741',
      //     allowOutsideClick: true,
      //     width: '40em',
      //     customClass: {
      //       confirmButton: 'btn action-button-popup',
      //       cancelButton: 'btn action-button-popup',
      //     },
      //   });

      //   if (sameDayWarning.isDismissed) {
      //     return;
      //   }
      // }

      // const isBookingAgentOverlap = checkBookingsOverlap(
      //   agentsBookings,
      //   followUpSubmissionPayload?.events![0] ?? events[0],
      // );

      // if (groupBooking === false || preloadBooking?.group_booking === false) {
      //   if (isBookingAgentOverlap !== false && bookingEvents[0].booking !== isBookingAgentOverlap.id) {
      //     const sameTimeError = await Swal.fire({
      //       title: t`errors.agentBookingsOverlap`,
      //       html: `<p>${t('errors.patientBookignsOverlapMessage')}</p>
      //     <ul>
      //     <li><a href="${currentPath}?eventId=${isBookingAgentOverlap?.events![0].id}">ID: ${
      //         isBookingAgentOverlap.public_id
      //       }</a></li>
      //     </ul>`,
      //       icon: 'error',
      //       showConfirmButton: false,
      //       showCancelButton: true,
      //       cancelButtonText: t`actions.cancel`,
      //       cancelButtonColor: '#dc3741',
      //       allowOutsideClick: true,
      //       width: '40em',
      //       customClass: {
      //         cancelButton: 'btn action-button-popup',
      //       },
      //     });

      //     if (sameTimeError.isDismissed) {
      //       return;
      //     }
      //   }
      // } else if (agentsBookings.length > 0 && bookingEvents[0].booking !== agentsBookings[0].id) {
      //   const sameDayWarning = await Swal.fire({
      //     title: t`warnings.agentHasBookings`,
      //     html: `<p>${t('warnings.agentHasBookingsMessage')}</p>
      //   <ul>
      //   ${agentsBookings
      //     .map(
      //       (booking) => `<li> <a href="${currentPath}?eventId=${booking.events![0].id}">ID: ${booking.public_id}</a>`,
      //     )
      //     .join('')}
      //   </ul>`,
      //     icon: 'warning',
      //     confirmButtonText: t`actions.continue`,
      //     confirmButtonColor: '#00a2b8',
      //     showCancelButton: true,
      //     cancelButtonText: t`actions.cancel`,
      //     cancelButtonColor: '#dc3741',
      //     allowOutsideClick: true,
      //     width: '40em',
      //     customClass: {
      //       confirmButton: 'btn action-button-popup',
      //       cancelButton: 'btn action-button-popup',
      //     },
      //   });

      //   if (sameDayWarning.isDismissed) {
      //     return;
      //   }
      // }

      if (preSubmit && !preSubmit(bookingPayload)) {
        return;
      }

      if (submitting) {
        return;
      }

      setSubmitting(true);

      // Update booking
      toast
        .promise(mutateAsyncUpdateBooking([preloadBooking?.id || preloadBookingId!, bookingPayload]), {
          pending: t`progress.bookingUpdate.started` as string,
          error: t`progress.bookingUpdate.error` as string,
          success: t`progress.bookingUpdate.success` as string,
        })
        .catch((err) => {
          setErrors(err);
          onSubmitFailure?.(err, bookingPayload);
          return Promise.reject();
        })
        .then(() => onSubmitSuccess?.(preloadBooking?.id || preloadBookingId!, bookingPayload))
        .then(() => setSubmitting(false))
        .finally(postSubmit);
    },
    [
      submitPreventDefault,
      setErrors,
      validate,
      bookingProvided,
      bookingIdProvided,
      canSubmit,
      retrieveReport,
      payerCompany?.id,
      payer?.payer_id,
      payerType,
      claimData,
      caseType,
      events,
      initialEventsList,
      childrenBookingIds,
      clinic,
      supportOperator,
      service,
      serviceRoot?.id,
      targetLanguage?.alpha3,
      bookingNotesList,
      requesterCompanySource,
      affiliation,
      requester,
      sendReminderTarget,
      preSubmit,
      submitting,
      mutateAsyncUpdateBooking,
      preloadBookingId,
      t,
      postSubmit,
      medicalProvider,
      onSubmitFailure,
      onSubmitSuccess,
      preloadBooking,
    ],
  );

  const onSubmit = useMemo(
    () => (preloadBooking?.id || preloadBookingId ? submitForUpdate : submitForCreation),
    [preloadBooking?.id, preloadBookingId, submitForCreation, submitForUpdate],
  );

  const onUpdatePatientField = (newAffiliation: UninsuredAffiliation | undefined) => {
    setAffiliation(newAffiliation ?? null);
    setShowPatient(false);
  };

  const onUpdateClinicField = (newClinic: Clinic | undefined) => {
    setClinic(newClinic ?? null);
    setShowClinic(false);
  };

  const onUpdateMedicalProviderField = (newMedicalProvider: Doctor | undefined) => {
    setMedicalProvider(newMedicalProvider ?? null);
    setShowMedicalProvider(false);
  };

  const onUpdateRequesterField = (newRequester: Requester | undefined) => {
    setRequester(newRequester ?? null);
    setShowRequester(false);
  };

  const onUpdateInterpreterField = (newService: Interpretation | undefined) => {
    setService(newService ?? null);
    setShowInterpreter(false);
  };

  const onUpdateOperatorField = (newOperator: Operator | undefined) => {
    setSupportOperator(newOperator ?? null);
    setShowSupportOperator(false);
  };

  const onSubmitClaim = async (claim: Claim) => {
    setClaimData(claim);
    if (!bookingProvided) {
      toast.info(t`claimCreatedOnSubmitNotice` as string);
    }
  };

  const onKeyDown: KeyboardEventHandler<HTMLFormElement> = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  // Provider and requester fields unselect on clinic change
  useEffect(() => {
    // Clear provider
    if (clinic !== initialClinic) {
      setMedicalProvider(null);
      // Clear requester
      if (requesterCompanySource === REQUESTER_TYPES.CLINIC_STAFF) {
        setRequester(null);
        setRequesterCompanySource(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic, initialClinic]);

  // Provider and requester fields unselect on clinic change new booking
  useEffect(() => {
    if (preloadBookingId === undefined) {
      setMedicalProvider(null);
      if (requesterCompanySource === REQUESTER_TYPES.CLINIC_STAFF) {
        setRequester(null);
        setRequesterCompanySource(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic]);

  useEffect(() => {
    if (requesterCompanySource !== initialRequesterCompanySource) {
      setRequester(null);
    }
  }, [requesterCompanySource, initialRequesterCompanySource, setRequester]);

  const date = formatDate(events[0].start_at);

  const eventsStartAt = events[0].start_at?.toLocaleString('en-US', {
    timeZone: 'America/Los_Angeles',
  });
  const eventsStartAtTime = new Date(eventsStartAt);

  return (
    <Form id={id} onSubmit={onSubmit} onKeyDown={onKeyDown}>
      <Row>
        <ErrorsAlert errorsArray={errors.non_field_errors} />
        <ErrorsAlert errorsArray={errors.validation_errors} />
        <Col xl={6}>
          <PatientField
            showPatientField={showPatient}
            setShowPatientField={setShowPatient}
            affiliation={affiliation}
            setAffiliation={changeAffiliation}
            onUpdateSuccess={onUpdatePatientField}
            preloadBookingId={preloadBookingId}
          />

          <EventListField
            formPrefix={p`event`}
            showEventField={showEvent}
            setShowEventField={setShowEvent}
            events={events}
            pushEvent={pushEvent}
            replaceEventAt={replaceEventAt}
            removeEventAt={removeEventAt}
            setEvents={setEvents}
            newEvent={newEvent}
            initialEventsList={initialEventsList}
            followUp={followUp}
          />

          <ServiceField
            formPrefix={p`service`}
            showService={showService}
            setShowService={setShowService}
            setService={setService}
            serviceRoot={serviceRoot}
            setServiceRoot={setServiceRootWithConfirmation}
            serviceRoots={serviceRoots}
            isServiceRootLoading={isServiceRootLoading}
            targetLanguage={targetLanguage}
            setTargetLanguage={setTargetLanguageWithConfirmation}
          />

          <ClinicField
            showClinicField={showClinic}
            setShowClinicField={setShowClinic}
            clinic={clinic}
            setClinic={setClinicWithConfirmation}
            onUpdateSuccess={onUpdateClinicField}
          />

          <MedicalProviderField
            showMedicalProviderField={showMedicalProvider}
            setShowMedicalProviderField={setShowMedicalProvider}
            medicalProvider={medicalProvider}
            setMedicalProvider={setMedicalProviderWithConfirmation}
            clinic={clinic}
            onUpdateSuccess={onUpdateMedicalProviderField}
          />

          <RequesterField
            showRequesterField={showRequester}
            setShowRequesterField={setShowRequester}
            requester={requester}
            setRequester={setRequesterWithConfirmation}
            requesterCompanySource={requesterCompanySource}
            setRequesterCompanySource={setRequesterCompanySource}
            clinic={clinic}
            payerType={payerType}
            payerCompany={payerCompany}
            onUpdateSuccess={onUpdateRequesterField}
          />
        </Col>
        <Col xl={6}>
          <CaseField
            user={user}
            showCaseField={showCase}
            setShowCaseField={setShowCase}
            bookingExists={!!preloadBooking?.id}
            bookingStatus={currentBookingStatus}
            patient={affiliation?.recipient}
            caseType={caseType}
            setCaseType={setCaseTypeWithConfirmation}
            claim={claimData}
            setClaim={setClaimData}
            agency={agency}
            setAgency={setAgency}
            parentSubmitClaim={onSubmitClaim}
            onSubmitSuccess={hideCaseField}
            initialPayerFieldState={initialPayerFieldState}
            payerCompany={payerCompany}
            start_at={date}
            clinic={clinic?.name}
            contactClinic={clinic?.locations?.[0]}
            medicalProvider={medicalProvider?.first_name}
            medicalProviderLastName={medicalProvider?.last_name}
            serviceRoot={serviceRoot?.description}
            serviceProvider={service?.provider?.first_name}
            serviceProviderLastName={service?.provider?.last_name}
            typeOfCertification={serviceRoot?.categories[1]?.name}
            duration={eventsStartAtTime}
            patient_first_name={affiliation?.recipient?.first_name}
            patient_last_name={affiliation?.recipient?.last_name}
          />

          <PayerField
            showPayerField={showPayer}
            setShowPayerField={setShowPayer}
            claim={claimData}
            setClaim={setClaimData}
            payerType={payerType}
            setPayerType={setPayerTypeWithConfirmation}
            payerCompany={payerCompany}
            setPayerCompany={setPayerCompany}
            payer={payer}
            setPayer={setPayer}
            initialPayerFieldState={initialPayerFieldState}
          />

          {caseType !== CASE_TYPES.REFERRED_TO_AGENCY ? (
            <>
              <InterpreterField
                showInterpreter={showInterpreter}
                setShowInterpreter={setShowInterpreter}
                setGetInterpreterSectionOpened={setGetInterpreterSectionOpened}
                service={service}
                setService={setServiceWithConfirmation}
                targetLanguage={targetLanguage}
                serviceRoot={serviceRoot}
                offers={offers}
                setOffers={setOffers}
                preloadBookingId={preloadBooking?.id}
                refetchOffers={refetchOffers}
                onUpdateSuccess={onUpdateInterpreterField}
              />
              <SupportOperatorField
                showSupportOperator={showSupportOperator}
                setShowSupportOperator={setShowSupportOperator}
                operator={supportOperator}
                setOperator={setSupportOperatorWithConfirmation}
                sendReminderTarget={sendReminderTarget}
                setSendReminderTarget={setSendReminderTarget}
                onUpdateSuccess={onUpdateOperatorField}
              />
            </>
          ) : null}

          {(bookingProvided || bookingIdProvided) && caseType !== CASE_TYPES.REFERRED_TO_AGENCY ? (
            <>
              <ReportField
                formPrefix={p`service-report`}
                showReport={showReport}
                setShowReport={setShowReport}
                setRetrieveReport={setRetrieveReport}
                initialReport={initialReport}
                disabled={!hasPatientDoB}
                prefillEvent={preloadBooking?.events?.[0]}
              />

              <FollowUpField
                formPrefix={p`followup`}
                showFollowUp={showFollowUp}
                setShowFollowUp={setShowFollowUp}
                bookingId={preloadBooking?.id}
                targetLanguage={targetLanguage}
                serviceRoot={serviceRoot}
                createFollowUpBooking={submitForCreation}
                parentBookingId={parentBookingId}
              />
            </>
          ) : null}

          <NotesListField
            formPrefix={p`booking-notes`}
            showNotesList={showBookingNotes}
            setShowNotesList={setShowBookingNotes}
            labelText={t`bookingNotes`}
            notesList={bookingNotesList}
            pushNote={pushBookingNote}
            replaceNoteAt={replaceBookingNoteAt}
            removeNoteAt={removeBookingNoteAt}
          />
        </Col>
      </Row>
    </Form>
  );
}

export default BookingOnboardingForm;
