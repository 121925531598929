import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button, Col, Container, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import { GridColDef, GridRowParams, GridRowsProp } from '@mui/x-data-grid';
import { CircularProgress, Typography } from '@mui/material';
import 'src/assets/styles/Tables.scss';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { isEmpty } from 'lodash';
import { FormTable } from '../../../components/Form/FormTable/FormTable';
import { getInterpretersPaginated, MUTATION_KEYS, ProvidersGetParams } from '../../../app/api';
import { useStateDict } from '../../../app/hooks';
import InterpreterOnboardingForm from './Onboarding/Form';
import { Interpreter } from '../../../app/types/Entities';
import { userToString } from '../../../app/helpers/mappers';
import TableSearchBar from '../TableSearchBar/TableSearchBar';

export default function InterpretersTable() {
  const { t } = useTranslation('tables');

  // Pagination
  const [paginationModel, setPaginationModel] = useState<{ page: number; pageSize: number }>({ page: 0, pageSize: 25 });

  const [queryFilters, setQueryFilters] = useStateDict<ProvidersGetParams>({
    page: paginationModel.page + 1,
    page_size: paginationModel.pageSize,
  });

  useEffect(() => {
    setQueryFilters({
      ...queryFilters,
      page: paginationModel.page + 1,
      page_size: paginationModel.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel, setQueryFilters]);

  // SearchBar
  const [searchParams, setSearchParams, updateSearchParams] = useStateDict<ProvidersGetParams>({});
  const [results, setResults] = useState<Interpreter[] | null>(null);

  const handleResultsChange = (data: any) => {
    setResults(data);
  };

  const transformedResults =
    results?.map((interpreter): any => {
      const phone = interpreter.contacts?.find((contact) => contact.phone)?.phone;

      return {
        ...interpreter,
        phone,
      };
    }) ?? [];

  const noSearchParams = isEmpty(searchParams.first_name) && isEmpty(searchParams.last_name);

  // Query
  const [interpretersRefetchEnabled, setInterpretersRefetchEnabled] = useState<boolean>(true);
  const {
    data: interpretersPaginatedData,
    isLoading,
    refetch: refetchInterpretersPaginatedData,
  } = useQuery([MUTATION_KEYS.PROVIDERS, queryFilters], () => getInterpretersPaginated(queryFilters), {
    enabled: interpretersRefetchEnabled,
  });

  useEffect(() => {
    if (interpretersPaginatedData) {
      setInterpretersRefetchEnabled(false);
    }
  }, [interpretersPaginatedData]);

  const filteredInterpreters =
    interpretersPaginatedData?.results?.map((interpreter): any => {
      const phone = interpreter.contacts?.find((contact) => contact.phone)?.phone;

      return {
        ...interpreter,
        phone,
      };
    }) ?? [];

  const [rowCountState, setRowCountState] = useState(interpretersPaginatedData?.count || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      interpretersPaginatedData?.count !== undefined ? interpretersPaginatedData.count : prevRowCountState,
    );
  }, [interpretersPaginatedData?.count, setRowCountState]);

  const [selectedInterpreter, setSelectedInterpreter] = useState<Interpreter | undefined>();
  const [showInterpreterModal, setShowInterpreterModal] = useState<boolean>(false);
  // const interpreterModalType = selectedInterpreter !== undefined ? 'updating' : 'onboarding';
  const [bookingPreloadStatus, setPreloadStatus] = useState<'loading' | 'success' | 'error' | undefined>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const hideModal = () => {
    setShowInterpreterModal(false);
    setSelectedInterpreter(undefined);
    setPreloadStatus(undefined);
  };

  const rows: GridRowsProp = (noSearchParams ? filteredInterpreters : transformedResults).map((interpreter) => {
    return {
      id: interpreter.id,
      first_name: interpreter.first_name,
      last_name: interpreter.last_name,
      phone: interpreter.phone,
      email: interpreter.email,
    };
  });

  const columns: GridColDef[] = [
    {
      field: 'first_name',
      headerName: t`interpreter.firstName`,
      flex: 1,
    },
    {
      field: 'last_name',
      headerName: t`interpreter.lastName`,
      flex: 1,
    },
    {
      field: 'phone',
      headerName: t`interpreter.phone`,
      flex: 1,
    },
    {
      field: 'email',
      headerName: t`interpreter.email`,
      flex: 1,
    },
  ];

  return (
    <>
      <Container className="w-100 h-100 mw-100">
        <Row className="w-100">
          <Col className="d-flex align-items-center">
            <TableSearchBar
              onResultsChange={handleResultsChange}
              placeholderOne={t('interpreter.filterFirstName')}
              placeholderTwo={t('interpreter.filterLastName')}
              queryType="interpreters"
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              updateSearchParams={updateSearchParams}
            />
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            <Typography className="me-4">
              {t('interpreter.totalInterpreters', { interpreters: interpretersPaginatedData?.count })}
            </Typography>

            <Button
              color="light"
              className="my-3 Table-Add-Button"
              onClick={() => {
                setSelectedInterpreter(undefined);
                setShowInterpreterModal(true);
                setPreloadStatus(undefined);
              }}
            >
              <AddIcon fontSize="large" />
            </Button>
          </Col>
        </Row>

        <Row className="w-100 h-100">
          <Col className="d-flex flex-column">
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  flexDirection: 'column',
                }}
              >
                <CircularProgress size="4rem" sx={{ color: '#00A2B8' }} />
                <span>
                  <b>{t`loadingTable`}</b>
                </span>
              </div>
            ) : (
              <FormTable
                rows={rows}
                rowCount={rowCountState}
                columns={columns}
                sx={{ backgroundColor: 'white', minHeight: '200px' }}
                onRowClick={(params: GridRowParams) => {
                  const interpreterId = params.row.id;
                  const interpreter = interpretersPaginatedData?.results.find(({ id }) => id === interpreterId);
                  setSelectedInterpreter(interpreter);
                  setShowInterpreterModal(true);
                  if (bookingPreloadStatus === undefined) {
                    setPreloadStatus('loading');
                  }
                }}
                paginationModel={paginationModel}
                paginationMode="server"
                setPaginationModel={setPaginationModel}
              />
            )}
          </Col>
        </Row>
      </Container>

      <Modal fullscreen className="admin-modal" isOpen={showInterpreterModal} toggle={hideModal}>
        <ModalHeader className="admin-modal-header">
          <Row className="align-items-center">
            <Col>
              <Button
                className="action-button"
                color="submit"
                disabled={isSubmitting}
                form="interpreter-onboarding-form"
              >
                {isSubmitting ? <Spinner className="me-2" size="sm" type="border" color="primary" /> : t`save`}
              </Button>
            </Col>

            <Col xs="auto">
              <Button color="transparent" disabled={isSubmitting} onClick={hideModal}>
                <CloseIcon fontSize="large" style={{ fill: 'white' }} />
              </Button>
            </Col>
          </Row>
        </ModalHeader>

        <ModalBody className="admin-modal-body">
          <div className="admin-modal-content-header">
            <Row className="align-items-center">
              <Col xs="auto">
                {selectedInterpreter !== undefined ? userToString(selectedInterpreter) : t('interpreter.modalTitleNew')}
              </Col>
            </Row>
          </div>
          <InterpreterOnboardingForm
            id="interpreter-onboarding-form"
            preloadInterpreter={selectedInterpreter ?? undefined}
            preSubmit={() => {
              setIsSubmitting(true);
              return true;
            }}
            onSubmitSuccess={() => {
              refetchInterpretersPaginatedData().then(hideModal);
            }}
            postSubmit={() => {
              setIsSubmitting(false);
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
}
