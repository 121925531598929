import { isNil, isString, negate } from 'lodash';
import { Category, Entity } from '../types/Entities';
import { CategoryPrefix } from './enum';
import { ValuesOf } from '../types/TypeMappers';

// Tools
export const invokeMap =
  <T = any, R = any>(func: (data: T) => R) =>
  (arr: Array<T>) =>
    arr.map(func);

export const invokeOnNotNil =
  <T, R>(func: (value: T) => R) =>
  (value: T) =>
    isNil(value) ? value : func(value);

// Checks
export const isFromEnum =
  <T extends { [s: string]: unknown } | ArrayLike<unknown>>(collection: T) =>
  (v: unknown): v is ValuesOf<T> =>
    Object.values(collection).includes(v);

export const isNotNil = negate(isNil);

export const isNumeric = (v: any) => !Number.isNaN(parseFloat(v));

export const isNumericOrNil = (v: any) => isNil(v) || isNumeric(v);

export const isStringOrNil = (v: any) => isNil(v) || isString(v);

export const isValueOf = (o: object) => (v: any) => Object.values(o).includes(v);

// Verifications
export const hasCategoryPrefix = (category: Category, prefix: CategoryPrefix): boolean =>
  category.name.includes(prefix);

// Converters
export const toNumberOrNil = invokeOnNotNil(Number);

export const toStringOrNil = invokeOnNotNil(String);

export const toBoolean = (v: any) => (typeof v === 'string' ? v.toLowerCase() === 'true' : Boolean(v));

// Sanitize functions
export function sanitize<T extends object, R>(
  data: T,
  field: keyof T,
  converter: (value: T[typeof field]) => R,
  assert: (value: T[typeof field]) => boolean = () => true,
): R | never {
  const value = data[field] as T[typeof field];
  if (!assert(value)) {
    console.error(`Setting field ${String(field)} to undefined because the value didn't pass the condition`, {
      value,
      data,
      assert,
    });
    return undefined as never;
  }

  try {
    return converter(value);
  } catch (error) {
    console.error(`Setting field ${String(field)} to undefined because the transformation failed`, {
      value,
      error,
      data,
      converter,
    });
  }
  return undefined as never;
}

export const sanitizeOrOmit = <T extends object, R>(
  data: T,
  omittedField: keyof T | undefined,
  field: keyof T,
  converter: (value: T[typeof field]) => R,
  assert: (value: T[typeof field]) => boolean = () => true,
): R | never => (omittedField === field ? (undefined as never) : sanitize(data, field, converter, assert));

export const sanitizeId = <T extends Entity>(data: T, omittedField: keyof T | undefined = undefined) =>
  sanitizeOrOmit(data, omittedField, 'id', Number, isNumeric);

export const sanitizeFK = <T extends Entity>(data: T, field: keyof T, omittedField: keyof T | undefined = undefined) =>
  sanitizeOrOmit(data, omittedField, field, Number, isNumeric);

export const sanitizeCurrency = <T extends Entity>(
  data: T,
  field: keyof T,
  omittedField: keyof T | undefined = undefined,
) => sanitizeOrOmit(data, omittedField, field, String, isNumeric);
