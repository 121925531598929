import { GridColDef, GridFilterInputDate, GridRowParams, GridRowsProp } from '@mui/x-data-grid';
import { BookingsPutMarked } from 'src/app/api';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { Skeleton } from '@mui/material';
import { BEvent } from 'src/app/types/Entities';
import { DisplayBookingModalFunction } from 'src/pages/BookingsPage/localTypes';
import { LightBEvent } from 'src/app/api/config/light_entities';
import { useNavigate } from 'react-router-dom';
import { buildSearchParam } from 'src/app/helpers/mappers';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import { NOTE_DATE_FORMAT_MOMENT } from 'src/app/helpers/constants';
import { FormTable } from '../../../components/Form/FormTable/FormTable';
import './List.scss';
import {
  extractBookingFromEvent,
  extractBookingFromLightEvent,
  isEventPayerStateValid,
} from '../../../app/helpers/services';

interface BookingsListProps {
  updateEvents?: (data: any, events: any) => void;
  bookingEvents?: BookingsPutMarked[];
  filteredEvents: LightBEvent[] | BEvent[];
  displayBookingModal: DisplayBookingModalFunction;
  bookingPreloadStatus?: 'loading' | 'success' | 'error';
  paramEventId: string | null;
  paginationModel?: { pageSize: number; page: number };
  setPaginationModel: (paginationModel: { pageSize: number; page: number }) => void;
  rowCount?: number;
  handleSortModelChange?: (sortModel: any) => void;
  handleFilterModelChange?: (filterModel: any) => void;
  invoicable?: boolean;
  exportable?: boolean;
  isLoading?: boolean;
}

const isFullEvent = (event: BEvent | LightBEvent): event is BEvent => {
  return (event as BEvent).reports !== undefined;
};

const translationArray = ['viewers', 'booking'];

export default function BookingsList({
  updateEvents,
  bookingEvents,
  filteredEvents,
  displayBookingModal,
  bookingPreloadStatus,
  paramEventId,
  paginationModel,
  setPaginationModel,
  rowCount,
  handleSortModelChange,
  handleFilterModelChange,
  invoicable,
  exportable,
  isLoading,
}: BookingsListProps) {
  const { t } = useTranslation(translationArray);
  const navigate = useNavigate();

  const rows: GridRowsProp = filteredEvents.map((event) => {
    return {
      id: event.id,
      booking: invoicable
        ? isFullEvent(event)
          ? extractBookingFromEvent(event)
          : extractBookingFromLightEvent(event)
        : extractBookingFromLightEvent(event as LightBEvent),
      booking_status: event.booking.cCurrentStatus.name,
      case_type: event.case_type,
      send_method: event.payer_company?.send_method,
      public_id: event.booking.public_id ? `${event.booking.public_id}` : '-',
      start_hour: moment(event.start_at).format('h:mm A'),
      start_date: event.start_at,
      provider: event.booking.services[0]
        ? `${event.booking.services[0]?.provider.first_name} ${event.booking.services[0]?.provider.last_name}`
        : '-',
      patient: event.affiliates?.[0]
        ? `${event.affiliates[0]?.recipient.first_name} ${event.affiliates[0]?.recipient.last_name}`
        : '-',
      clinic: event.booking.companies.filter((company) => company.type === 'clinic')[0]?.name,
      payer: isFullEvent(event) ? event.payer?.first_name : '-',
    };
  });

  const currentPath = window.location.pathname;
  const columns: GridColDef[] = [
    {
      field: 'start_date',
      headerName: t`booking.date`,
      type: 'date',
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div className="booking-cell">
            {isLoading ? <Skeleton /> : moment(params.value).format(NOTE_DATE_FORMAT_MOMENT)}
          </div>
        );
      },
      minWidth: 100,
      sortingOrder: ['desc', 'asc', null],
      hideable: false,
      filterOperators: [
        {
          label: 'Is',
          value: 'is',
          InputComponent: GridFilterInputDate,
          InputComponentProps: { type: 'date' },
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.field || !filterItem.value) {
              return null;
            }
            return ({ value }): boolean => {
              return new Date(value).toDateString() === new Date(filterItem.value).toDateString();
            };
          },
        },
        {
          label: 'On or before',
          value: 'onOrBefore',
          InputComponent: GridFilterInputDate,
          InputComponentProps: { type: 'date' },
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.field || !filterItem.value) {
              return null;
            }
            return ({ value }): boolean => {
              return new Date(value) <= new Date(filterItem.value);
            };
          },
        },
        {
          label: 'On or after',
          value: 'onOrAfter',
          InputComponent: GridFilterInputDate,
          InputComponentProps: { type: 'date' },
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.field || !filterItem.value) {
              return null;
            }
            return ({ value }): boolean => {
              return new Date(value) >= new Date(filterItem.value);
            };
          },
        },
      ],
    },
    {
      field: 'booking_status',
      headerName: t`booking.status`,
      flex: 0.5,
      renderCell: (params) => (
        <div
          className={classNames(
            'booking-status-cell',
            invoicable && isLoading ? null : params.row.booking.cCurrentStatus.name,
          )}
        >
          {isLoading ? <Skeleton /> : t(params.row.booking.cCurrentStatus.name)}
        </div>
      ),
      minWidth: 90,
      hideable: false,
      sortable: false,
      filterable: false,
    },
    {
      field: 'pending',
      headerName: t`booking.pending`,
      flex: 0.5,
      renderCell: (params) => {
        const elements = [];
        if (params.row.claim === undefined) {
          elements.push(<WorkOffIcon className="list-icon" />);
        }
        if (
          params.row.booking.events[0] &&
          !isEventPayerStateValid(
            params.row.booking.events[0].payer,
            params.row.booking.events[0].payer_company,
            params.row.booking.events[0].payer_company_type,
          )
        ) {
          elements.push(<MoneyOffIcon className="list-icon" />);
        }
        if (params.row.booking.services[0] === undefined) {
          elements.push(<PersonOffIcon className="list-icon" />);
        }

        return <div className="booking-cell">{isLoading ? <Skeleton /> : elements}</div>;
      },
      minWidth: 100,
      hideable: false,
      filterable: false,
      sortable: false,
    },
    {
      field: 'start_hour',
      headerName: t`booking.startHour`,
      flex: 0.5,
      minWidth: 100,
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return <div className="booking-cell">{isLoading ? <Skeleton /> : params.value}</div>;
      },
    },
    {
      field: 'public_id',
      headerName: t`booking.id`,
      flex: 1,
      minWidth: currentPath === '/admin' ? 100 : 150,
      maxWidth: currentPath === '/admin' ? 175 : 250,
      renderCell: (params) => {
        if (params.row.public_id !== '-') {
          return (
            <div className="booking-cell">{isLoading ? <Skeleton /> : <strong>#{params.row.public_id}</strong>}</div>
          );
        }
        return (
          <div className="booking-cell">
            {t(params.row.booking.cCurrentStatus.name) === 'unknown' ? (
              <Skeleton />
            ) : (
              <strong>{params.row.public_id}</strong>
            )}
          </div>
        );
      },
      hideable: false,
      filterable: false,
    },
    {
      field: 'provider',
      headerName: t`booking.provider`,
      flex: 1,
      minWidth: currentPath === '/admin' ? 100 : 180,
      maxWidth: currentPath === '/admin' ? 175 : 280,
      hideable: false,
      filterable: false,
      renderCell: (params) => {
        return <div className="booking-cell-large">{isLoading ? <Skeleton /> : params.value}</div>;
      },
    },
    {
      field: 'patient',
      headerName: t`booking.recipient`,
      flex: 1,
      minWidth: currentPath === '/admin' ? 100 : 180,
      maxWidth: currentPath === '/admin' ? 210 : 280,
      hideable: false,
      filterable: false,
      renderCell: (params) => {
        return <div className="booking-cell-large">{isLoading ? <Skeleton /> : params.value}</div>;
      },
    },
    {
      field: 'clinic',
      headerName: t`booking.clinic`,
      flex: 1,
      minWidth: 180,
      hideable: false,
      filterable: false,
      renderCell: (params) => {
        return <div className="booking-cell-large">{isLoading ? <Skeleton /> : params.value}</div>;
      },
    },
  ];

  if (currentPath === '/admin') {
    columns.push(
      {
        field: 'caseType',
        headerName: t`booking.case_type`,
        flex: 0.5,
        renderCell: (params) => {
          return <div className="booking-cell">{isLoading ? <Skeleton /> : params.row.case_type}</div>;
        },
        minWidth: 100,
        sortingOrder: ['desc', 'asc', null],
        hideable: false,
        filterable: false,
      },
      {
        field: 'sendMethod',
        headerName: t`booking.send_method`,
        flex: 0.5,
        renderCell: (params) => {
          return (
            <div className="booking-cell" style={{ textAlign: 'center' }}>
              {isLoading ? <Skeleton /> : params.row.send_method}
            </div>
          );
        },
        minWidth: 100,
        sortingOrder: ['desc', 'asc', null],
        hideable: false,
        filterable: false,
      },
    );
  }
  return (
    <Container className="w-100 h-100 mw-100 p-0">
      <FormTable
        invoicable={invoicable}
        exportable={exportable}
        events={bookingEvents}
        columns={columns}
        rows={rows}
        rowCount={rowCount}
        onRowClick={(params: GridRowParams) => {
          displayBookingModal(
            params.row.booking.public_id,
            params.row.booking.start_at,
            params.row.booking.end_at,
            params.row.booking.cCurrentStatus,
            bookingPreloadStatus,
            params.row.booking,
          );

          if (paramEventId !== params.row.id) {
            navigate({
              pathname: '/bookings/list',
              search: buildSearchParam({ eventId: params.row.id }),
            });
          }
        }}
        paginationModel={paginationModel}
        paginationMode="server"
        setPaginationModel={setPaginationModel}
        sortingMode="server"
        handleSortModelChange={handleSortModelChange}
        filterMode="server"
        handleFilterModelChange={handleFilterModelChange}
        initialState={{
          sorting: {
            sortModel: [{ field: 'start_date', sort: 'desc' }],
          },
        }}
        updateEvents={updateEvents}
      />
    </Container>
  );
}
