import { Dispatch, SetStateAction } from 'react';
import { Box, Drawer } from '@mui/material';
import './BookingAside.scss';
import { useTranslation } from 'react-i18next';
import BookingStatus from '../BookingStatus/BookingStatus';

interface BookingAsideProps {
  drawerOpen?: boolean;
  status: string;
  setStatus: Dispatch<SetStateAction<string>>;
  hidden: boolean;
}

const translationArray = ['calendar'];

// TODO: Remove hidden when booking aside filters work

export default function BookingAside({ drawerOpen, status, setStatus, hidden }: BookingAsideProps) {
  const { t } = useTranslation(translationArray);

  return (
    <Drawer
      variant="persistent"
      sx={{
        zIndex: 0,
        width: '240px',
        minWidth: 240,
        flexShrink: 0,
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '240px', minWidth: 240 },
        display: hidden ? 'none' : 'block',
      }}
      anchor="left"
      open={drawerOpen}
    >
      <Box className="booking-aside">
        <h5 className="booking-aside-header-title">{t`sidebar.title`}</h5>
        <BookingStatus status={status} setStatus={setStatus} />
      </Box>
    </Drawer>
  );
}
